<form [formGroup]="form" (submit)="save()">
  <div>
    <!-- lid -->
    <mat-form-field>
      <mat-label>LID</mat-label>
      <input matInput [formControl]="lid" />
      <mat-error *ngIf="lid.errors?.required && (lid.dirty || lid.touched)"
        >lid required</mat-error
      >
      <mat-error *ngIf="lid.errors?.maxLength && (lid.dirty || lid.touched)"
        >lid too long</mat-error
      >
    </mat-form-field>
    &nbsp;
    <!-- pos (bound) -->
    <mat-form-field *ngIf="posEntries?.length" style="width: 8em">
      <mat-label>POS</mat-label>
      <mat-select [formControl]="pos">
        <mat-option *ngFor="let e of posEntries" [value]="e.id">{{
          e.value
        }}</mat-option>
      </mat-select>
      <mat-error *ngIf="pos.errors?.required && (pos.dirty || pos.touched)"
        >POS required</mat-error
      >
    </mat-form-field>

    <!-- pos (free) -->
    <mat-form-field *ngIf="!posEntries?.length" style="width: 8em">
      <mat-label>POS</mat-label>
      <input matInput [formControl]="pos" />
      <mat-error *ngIf="pos.errors?.required && (pos.dirty || pos.touched)"
        >POS required</mat-error
      >
      <mat-error *ngIf="pos.errors?.maxLength && (pos.dirty || pos.touched)"
        >POS too long</mat-error
      >
    </mat-form-field>
  </div>
  <div>
    <!-- prelemma -->
    <mat-form-field style="width: 4em">
      <mat-label>pre</mat-label>
      <input matInput [formControl]="prelemma" />
      <mat-error
        *ngIf="
          prelemma.errors?.maxLength && (prelemma.dirty || prelemma.touched)
        "
        >too long</mat-error
      >
    </mat-form-field>

    &nbsp;
    <!-- lemma -->
    <mat-form-field>
      <mat-label>lemma</mat-label>
      <input matInput [formControl]="lemma" />
      <mat-error
        *ngIf="lemma.errors?.required && (lemma.dirty || lemma.touched)"
        >lemma required</mat-error
      >
      <mat-error
        *ngIf="lemma.errors?.maxLength && (lemma.dirty || lemma.touched)"
        >lemma too long</mat-error
      >
    </mat-form-field>

    &nbsp;
    <!-- postlemma -->
    <mat-form-field style="width: 5em">
      <mat-label>post</mat-label>
      <input matInput [formControl]="postlemma" />
      <mat-error
        *ngIf="
          postlemma.errors?.maxLength && (postlemma.dirty || postlemma.touched)
        "
        >too long</mat-error
      >
    </mat-form-field>
    <!-- homograph -->
    <mat-form-field style="width: 4em">
      <mat-label>hom.</mat-label>
      <input matInput type="number" min="0" [formControl]="homograph" />
    </mat-form-field>
  </div>
  <div>
    <!-- note -->
    <mat-form-field class="long-text">
      <mat-label>note</mat-label>
      <textarea matInput rows="2" [formControl]="note"></textarea>
      <mat-error *ngIf="note.errors?.maxLength && (note.dirty || note.touched)"
        >note too long</mat-error
      >
    </mat-form-field>
  </div>

  <!-- variants -->
  <div formArrayName="variants">
    <div>
      <button
        type="button"
        mat-flat-button
        class="mat-primary"
        (click)="addVariant()"
      >
        <mat-icon>add_circle</mat-icon>
        variant
      </button>
    </div>
    <div
      *ngFor="
        let item of variants.controls;
        let i = index;
        let first = first;
        let last = last
      "
    >
      <!-- child form -->
      <div [formGroupName]="i">
        <!-- child actions -->
        {{ i + 1 }}.
        <button
          mat-icon-button
          type="button"
          matTooltip="Remove this variant"
          (click)="removeVariant(i)"
        >
          <mat-icon class="mat-warn">remove_circle</mat-icon>
        </button>
        <button
          [disabled]="first"
          mat-icon-button
          type="button"
          matTooltip="Move variant up"
          (click)="moveVariantUp(i)"
        >
          <mat-icon>arrow_upward</mat-icon>
        </button>
        <button
          [disabled]="last"
          mat-icon-button
          type="button"
          matTooltip="Move variant down"
          (click)="moveVariantDown(i)"
        >
          <mat-icon>arrow_downward</mat-icon>
        </button>

        <!-- child controls -->
        <!-- tag (bound) -->
        <mat-form-field *ngIf="varTagEntries?.length" style="width: 8em">
          <mat-label>tag</mat-label>
          <mat-select formControlName="tag">
            <mat-option *ngFor="let e of varTagEntries" [value]="e.id">{{
              e.value
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- tag (free) -->
        <mat-form-field *ngIf="!varTagEntries?.length" style="width: 8em">
          <mat-label>tag</mat-label>
          <input matInput formControlName="tag" />
          <mat-error
            *ngIf="
              $any(item)['controls'].tag.errors?.maxLength &&
              ($any(item)['controls'].tag.dirty ||
                $any(item)['controls'].tag.touched)
            "
            >tag too long</mat-error
          >
        </mat-form-field>
        &nbsp;
        <!-- pos (bound) -->
        <mat-form-field *ngIf="posEntries?.length" style="width: 8em">
          <mat-label>POS</mat-label>
          <mat-select formControlName="pos">
            <mat-option *ngFor="let e of posEntries" [value]="e.id">{{
              e.value
            }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              $any(item)['controls'].pos.errors?.required &&
              ($any(item)['controls'].pos.dirty ||
                $any(item)['controls'].tag.touched)
            "
            >POS required</mat-error
          >
        </mat-form-field>
        <!-- pos (free) -->
        <mat-form-field *ngIf="!posEntries?.length" style="width: 8em">
          <mat-label>POS</mat-label>
          <input matInput formControlName="pos" />
          <mat-error
            *ngIf="
              $any(item)['controls'].pos.errors?.maxLength &&
              ($any(item)['controls'].pos.dirty ||
                $any(item)['controls'].tag.touched)
            "
            >POS too long</mat-error
          >
        </mat-form-field>
        &nbsp;
        <!-- value -->
        <mat-form-field>
          <mat-label>value</mat-label>
          <input matInput formControlName="value" />
          <mat-error
            *ngIf="
              $any(item)['controls'].value.errors?.required &&
              ($any(item)['controls'].value.dirty ||
                $any(item)['controls'].value.touched)
            "
            >value required</mat-error
          >
          <mat-error
            *ngIf="
              $any(item)['controls'].value.errors?.maxLength &&
              ($any(item)['controls'].value.dirty ||
                $any(item)['controls'].value.touched)
            "
            >value too long</mat-error
          >
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- buttons -->
  <div>
    <button
      type="button"
      mat-icon-button
      matTooltip="Discard form changes"
      (click)="cancel()"
    >
      <mat-icon class="mat-warn">clear</mat-icon>
    </button>
    <button
      type="submit"
      mat-icon-button
      matTooltip="Accept form changes"
      [disabled]="form.invalid || form.pristine"
    >
      <mat-icon class="mat-primary">check_circle</mat-icon>
    </button>
  </div>
</form>
